import React from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { CoverBillboardCenter, HeroProduct, HeroInception } from 'kinetiq'
import { Partners } from '../components/Partners'
import { SVGImage } from '../components/SVGImage'
import { PageContent } from '../components/PageContent'

const IndexPage = () => (
  <Layout>
    <SEO title='Home' />
    <CoverBillboardCenter
      image=''
      tagline='Powering a more intelligent future'
      text="Artificial intelligence infrastructure and software solutions empowering organizations to tackle the world's most challenging problems"
    />
    <PageContent>
      <HeroProduct
        image={<SVGImage variant='HeroHomeSol' />}
        link='/sol'
        title='Sol'
        tagline='Cloud infrastructure purpose-built for artificial intelligence'
        text='Serve, manage, and scale the applications of the future. We take care of the infrastructure so you can concentrate on the problem.'
        alternate
      />
      <HeroProduct
        image={<SVGImage variant='HeroHomeStarship' />}
        link='/starship'
        title='Starship'
        tagline='The complete toolkit for deploying artificial intelligence to production'
        text='Build, train, and deploy deep learning models in a single pane of glass. Operationalize your workflow and never worry about infrastructure again.'
      />
      <HeroInception
        backgroundImage='/heroes/home/nvidia-background.jpg'
        image={<SVGImage variant='HeroHomeNvidia' />}
        link='/files/Qoor-Nvidia-Inception-Program.pdf'
        title='Qoor joins NVIDIA as an artificial intelligence inception partner'
        text='Qoor supports the academic, scientific, and business communities with cutting-edge NVIDIA GPU technology.'
      />
      <Partners
        background='#021B33'
        title='Partners & Technology'
        partners={[
          <div>
            <SVGImage variant='PartnerNvidia' />
          </div>,
          <div>
            <SVGImage variant='PartnerGoodwin' />
          </div>,
          <div>
            <SVGImage variant='PartnerFireEye' />
          </div>,
        ]}
      />
    </PageContent>
  </Layout>
)

export default IndexPage
